import React, { useState } from 'react';

function Checkbox() {
  const [checked, setChecked] = useState(false);
  const textIn = 'Opt in for SMS'
  const text = 'By opting in, you agree to receive SMS messages from Right Start Mortgage delivered to your mobile device, even if your telephone number is currently listed on any internal, corporate, state, federal or national Do-Not-Call (DNC) list. SMS and MMS charges may apply. Consent is not required as a condition to utilizing Right Start Mortgage’s services.'; 

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
<div className='py-8 flex flex-col gap-4'>
        <label className=''>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <i className={`mr-5 border-2 p-0.5 my-2 border-gray-500 border-solid relative w-fit  far fa-check ${checked ? 'text-transparent' : '!text-primary-500'}`}></i>
      <span className="text-black text-sm">{textIn}</span>
    </label>
        <label className=''>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <i className={`mr-5 border-2 p-0.5 my-2 border-gray-500 border-solid relative w-fit  far fa-check ${checked ? 'text-transparent' : '!text-primary-500'}`}></i>
      <span className="text-black text-sm">{text}</span>
    </label>

</div>
  );
}

export default Checkbox;
