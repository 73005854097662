import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Header from "./Navigation/Header";
import Footer from "./Footer/Footer";

const Layout = ({
  children,
  navigationStyle,
  hideHeader,
  hideFooter,
  pageType,
}) => {
  const [bodyOffset, setBodyOffset] = useState(0);
  return (
    <div>
      <Helmet></Helmet>
      <Header
        navigationStyle={navigationStyle}
        hideHeader={hideHeader}
        setBodyOffset={setBodyOffset}
        pageType={pageType}
      />
      <main id="body-content" style={{ paddingTop: bodyOffset + "px" }}>
        {children}
      </main>
      <Footer  hideFooter={hideFooter} pageType={pageType} />
    </div>
  );
};

Layout.propTypes = {
  navigationStyle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
