import React from "react";
import { Link } from "gatsby";

import phone from "../../images/Global/phone.svg";
import payment from "../../images/Global/payment.svg";
import applyNow from "../../images/Global/Apply Now.svg";
import signIn from "../../images/Global/Login.svg";

const UtilityNav = ({ navigationStyle, pageType }) => {
  return (
    pageType !== "Landing" && (
      <div
        id="utlity-navigation"
        className={`hidden bg-primary-900 py-1.5 lg:block ${
          navigationStyle === "overlap" ? "" : ""
        }`}
      >
        <div className="container">
          <div className="flex items-center justify-between">
            <div>
              <a
                href="tel:800-520-5626"
                className="flex items-center font-heading text-sm font-semibold text-white no-underline hover:text-white"

              >
                <img src={phone} alt="Phone" className="mr-2" />
                (800) 520-5626
              </a>
            </div>
            <div className="flex items-center space-x-9">
              <div>
                <Link
                  to="/make-a-payment/"
                  className="flex items-center space-x-2 font-heading text-sm font-semibold text-white no-underline hover:text-white"
                >
                  <img src={payment} alt="Make a Payment" />
                  <span>Make a Payment</span>
                </Link>
              </div>
              <div>
                <a
                  href="https://www.yourmortgageonline.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center space-x-2 font-heading text-sm font-semibold text-white no-underline hover:text-white"
                >
                  <img src={signIn} alt="Login" />
                  <span>Login</span>
                </a>
              </div>
              <div>
                <button
                  data-modal-open="modal-apply"
                  className="flex items-center space-x-2 font-heading text-sm font-semibold text-white no-underline hover:text-white"
                >
                  <img src={applyNow} alt="Apply Now" />
                  <span>Apply Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default UtilityNav;
