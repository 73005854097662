import React, { Component } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import ButtonSolid from "../Button/ButtonSolid";

function encode(data) {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
        formData.append(key, data[key]);
    }

    return formData;
}

const StyledForm = styled.div`
   form {
      ${tw`flex flex-wrap`}
   }
   .field {
      ${tw`mb-6 w-full`}
   }
   .label {
      ${tw`block text-sm font-bold text-gray-800 mb-2`}
   }
   input[type="text"],
   input[type="tel"],
   input[type="email"],
   textarea {
      ${tw`w-full px-4 py-3 border border-solid border-gray-300 rounded-sm`}
      &:focus,
    &:active {
         ${tw`border-black outline-none`}
      }
   }
`;

export default class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleAttachment = (e) => {
        this.setState({ [e.target.name]: e.target.files[0] });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state,
            }),
        })
            .then(
                () =>
                    (document.getElementById("careers-form-ajax-response").innerHTML = "Thank you for applying! We will get in touch with you shortly."),
                form.remove(),
                (window.dataLayer = window.dataLayer || []),
                window.dataLayer.push({
                    event: "careersFormSubmission",
                })
            )
            .catch((error) => alert(error));
    };

    render() {
        return (
            <StyledForm>
                <div id="careers-form-ajax-response"></div>
                <form name="Careers" method="post" action="" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="Careers" />
                    <div hidden>
                        <label>
                            Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
                        </label>
                    </div>

                    <div className="field">
                        <label className="label" htmlFor="name">
                            Full Name
                        </label>
                        <input type="text" name="name" onChange={this.handleChange} id="name" required={true} />
                    </div>
                    <div className="grid w-full grid-cols-1 md:grid-cols-2 md:gap-x-6">
                    <div className="field">
                        <label className="label" htmlFor="email">
                            Email Address
                        </label>
                        <input type="email" name="email" onChange={this.handleChange} id="email" required={true} />
                    </div>
                
                    <div className="field">
                        <label className="label" htmlFor="phone">
                            Phone Number
                        </label>
                        <input type="tel" name="phone" onChange={this.handleChange} id="phone" required={true} />
                    </div>
                    </div>
                    <div className="field">
                        <label className="label" htmlFor="attachment">
                            Upload CV
                        </label>
                        <input type="file" name="attachment" onChange={this.handleAttachment} id="attachment" required={false} />
                    </div>
                    <div className="field">
                        <label className="label" htmlFor="message">
                            Message
                        </label>
                        <textarea name="message" onChange={this.handleChange} id="message" rows="4" required={true} />
                    </div>


                    <div className="mt-2">
                        <ButtonSolid as="button" type="submit" text="Send" />
                    </div>
                </form>
            </StyledForm>
        );
    }
}