import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "gatsby-plugin-transition-link";
import { StaticImage } from "gatsby-plugin-image";

import ModalBuydown from "../Modal/ModalBuydown";
import ModalContact from "../Modal/ModalContact";
import ModalApply from "../Modal/ModalApply";
import ModalCareers from "../Modal/ModalCareers";
import nestLogo from "../../images/Global/Nest Logo.svg";
import { mapEdgesToNodes } from "../../lib/helpers";

const Footer = ({ hideFooter, pageType }) => {
  const getYear = () => {
    return new Date().getFullYear();
  };

  const [statesDropdownOpen, setStatesDropdownOpen] = useState(false);
  const [cityDropdownOpen, setCityDropdownOpen] = useState(false);

  const locationsClickHandler = () => {
    setStatesDropdownOpen(!statesDropdownOpen);
    setCityDropdownOpen(false);
  };

  const industriesClickHandler = () => {
    setCityDropdownOpen(!cityDropdownOpen);
    setStatesDropdownOpen(false);
  };

  const data = useStaticQuery(graphql`
    {
      states: allSanityStates(sort: { fields: [title], order: ASC }) {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
      cities: allSanityCities(sort: { fields: [title], order: ASC }) {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const stateNodes = data && data.states && mapEdgesToNodes(data.states);
  const cityNodes = data && data.cities && mapEdgesToNodes(data.cities);

  const navigation = {
    explore: [
      {
        name: "Purchase",
        href: "/home-purchase/",
      },
      {
        name: "Refinance",
        href: "/home-refinance/",
      },
      {
        name: "Featured Loan Programs",
        href: "/home-loan-programs/",
      },
      {
        name: "Mortgage Rate Options",
        href: "/mortgage-rate-options/",
      },
    ],
    resources: [
      {
        name: "Loan Process",
        href: "/home-loan-process/",
      },
      {
        name: "Mortgage Basics",
        href: "/mortgage-basics/",
      },
      {
        name: "Mortgage Calculators",
        href: "/mortgage-calculators/",
      },
      {
        name: "Blog",
        href: "/blog/",
      },
      {
        name: "FAQs",
        href: "/faqs/",
      },
      {
        name: "Careers",
        href: "/careers/",
      },
    ],
    company: [
      {
        name: "About Us",
        href: "/about/",
      },
      {
        name: "Meet the Team",
        href: "/meet-the-team/",
      },
      {
        name: "Reviews",
        href: "/reviews/",
      },
    ],
    apply: [
      {
        name: "Request Rates",
        href: "/request-rates/",
      },
    ],
  };

  return (
    <>
      <footer
        id="page-footer"
        className={`relative z-20 bg-primary-50 pb-12 pt-16 text-center md:pb-6 md:text-left ${
          hideFooter && "hidden"
        }`}
      >
        <div className="container">
          {pageType === "Landing" ? (
            <>
              <div className="mb-10 space-y-10 md:mb-10 md:flex md:justify-between md:space-x-10 md:space-y-0 xl:space-x-20">
                <div>
                  <Link to="/">
                    <StaticImage
                      src="../../images/Global/Logo-Dark.png"
                      alt="Right Start Mortgage Logo"
                      loading="lazy"
                      width={189}
                      className="mx-auto mb-4 md:mx-0"
                    />
                  </Link>

                  <div className="text-sm font-bold text-typography-heading">
                    Company NMLS: 35960
                  </div>

                  <a
                    href="https://www.nmlsconsumeraccess.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm no-underline"
                  >
                    www.nmlsconsumeraccess.org
                  </a>
                </div>

                <div>
                  <div className="mb-5 font-bold text-typography-heading">
                    Get in Touch
                  </div>

                  <ul className="flex flex-col space-y-2">
                    <li>
                      <a
                        href="tel:800-520-5626"
                        className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                      >
                        (800) 520-5626
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:info@rightstartmortgage.com"
                        className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                      >
                        info@rightstartmortgage.com
                      </a>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="mb-5 font-bold text-typography-heading">
                    Apply
                  </div>
                  <ul className="flex flex-col space-y-2">
                    {navigation.apply.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                    <li>
                      <button
                        data-modal-open="modal-apply"
                        className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                      >
                        Apply Online
                      </button>
                    </li>
                    <li>
                      <a
                        href="https://www.yourmortgageonline.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                      >
                        Login
                      </a>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="mb-5 font-bold text-typography-heading">
                    Disclosure Requirements
                  </div>
                  <p className="text-sm">
                    Right Start Mortgage, Inc. is a licensed mortgage lender.
                    <br />
                    Arizona: Mortgage Banker License #BK-0905721
                    <br />
                    California: Licensed by Dept. of Business Oversight, under
                    the CA Residential Mortgage Lending Act and Finance Lenders
                    Law No 4131234
                  </p>
                </div>
              </div>
              <div className="flex flex-col flex-wrap justify-items-center md:flex-row md:flex-nowrap md:items-center md:justify-between md:space-x-4">
                <ul className="flex flex-wrap items-center justify-center space-x-4 md:justify-start">
                  <li className="font-body text-sm text-typography-heading/70">
                    Right Start Mortgage, Inc.
                  </li>

                  <li className="text-sm">
                    <Link
                      to="/privacy-policy/"
                      className="font-body font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>

                <div className="mt-2 w-full text-sm md:mt-0 md:w-auto">
                  <StaticImage
                    src="../../images/Global/equal-housing-lender.png"
                    alt="Equal Housing Lender Logo"
                    loading="lazy"
                    width={55}
                  />
                </div>
              </div>{" "}
            </>
          ) : (
            <>
              {" "}
              <div className="mb-10 flex items-center justify-center md:mb-12 md:justify-between">
                <div>
                  <Link to="/">
                    <StaticImage
                      src="../../images/Global/Logo-Dark.png"
                      alt="Right Start Mortgage Logo"
                      loading="lazy"
                      width={189}
                      className="mx-auto mb-4 md:mx-0"
                    />
                  </Link>

                  <div className="text-sm font-bold text-typography-heading">
                    Company NMLS: 35960
                  </div>
                  <a
                    href="https://www.nmlsconsumeraccess.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm no-underline"
                  >
                    www.nmlsconsumeraccess.org
                  </a>
                </div>

                <div className="hidden items-center justify-center space-x-6 md:flex md:justify-start">
                  <a
                    href="https://www.facebook.com/RightStartMortgage/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-typography-heading no-underline hover:text-primary-500"
                  >
                    <i className="fab fa-facebook-f text-2xl"></i>
                  </a>
                  <a
                    href="https://twitter.com/RightStartMtg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-typography-heading no-underline hover:text-primary-500"
                  >
                    <i className="fab fa-twitter text-2xl"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/rightstartmortgage/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-typography-heading no-underline hover:text-primary-500"
                  >
                    <i className="fab fa-instagram text-2xl"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/rightstart-mortgage"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-typography-heading no-underline hover:text-primary-500"
                  >
                    <i className="fab fa-linkedin-in text-2xl"></i>
                  </a>
                </div>
              </div>
              <div className="mb-10 space-y-10 md:mb-18 md:flex md:justify-between md:space-x-6 md:space-y-0 xl:space-x-10">
                <div>
                  <div className="mb-5 font-bold text-typography-heading">
                    Explore
                  </div>
                  <ul className="flex flex-col space-y-2">
                    {navigation.explore.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <div className="mb-5 font-bold text-typography-heading">
                    Resources
                  </div>
                  <ul className="flex flex-col space-y-2">
                    {navigation.resources.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <div className="mb-5 font-bold text-typography-heading">
                    Company
                  </div>
                  <ul className="flex flex-col space-y-2">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <div className="mb-5 font-bold text-typography-heading">
                    Get in Touch
                  </div>

                  <ul className="flex flex-col space-y-2">
                    <li>
                      <address className="text-sm not-italic">
                        <a
                          href="https://goo.gl/maps/uXmkr8kmmAMfrR1r8"
                          target="_blank"
                          rel="noreferrer"
                          className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                        >
                          80 South Lake Avenue, Suite 520
                          <br />
                          Pasadena, CA 91101
                        </a>
                      </address>
                    </li>
                    <li>
                      <a
                        href="tel:800-520-5626"
                        className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                      >
                        (800) 520-5626
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:info@rightstartmortgage.com"
                        className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                      >
                        info@rightstartmortgage.com
                      </a>
                    </li>
                  </ul>
                </div>

                <div>
                  <div className="mb-5 font-bold text-typography-heading">
                    Apply
                  </div>
                  <ul className="flex flex-col space-y-2">
                    {navigation.apply.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                    <li>
                      <button
                        data-modal-open="modal-apply"
                        className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                      >
                        Apply Online
                      </button>
                    </li>
                    <li>
                      <a
                        href="https://www.yourmortgageonline.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                      >
                        Login
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-9 flex items-center justify-center space-x-6 md:hidden md:justify-start">
                <a
                  href="https://www.facebook.com/RightStartMortgage/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-typography-heading no-underline hover:text-primary-500"
                >
                  <i className="fab fa-facebook-f text-2xl"></i>
                </a>
                <a
                  href="https://twitter.com/RightStartMtg"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-typography-heading no-underline hover:text-primary-500"
                >
                  <i className="fab fa-twitter text-2xl"></i>
                </a>
                <a
                  href="https://www.instagram.com/rightstartmortgage/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-typography-heading no-underline hover:text-primary-500"
                >
                  <i className="fab fa-instagram text-2xl"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/rightstart-mortgage"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-typography-heading no-underline hover:text-primary-500"
                >
                  <i className="fab fa-linkedin-in text-2xl"></i>
                </a>
              </div>
              <div className="flex flex-col flex-wrap justify-items-center md:flex-row md:flex-nowrap md:items-center md:justify-between md:space-x-4">
                <ul className="flex flex-wrap items-center justify-center space-x-4 md:justify-start">
                  <li className="font-body text-sm text-typography-heading/70">
                    Right Start Mortgage, Inc.
                  </li>

                  <li className="text-sm">
                    <Link
                      to="/privacy-policy/"
                      className="font-body font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                    >
                      Privacy Policy
                    </Link>
                  </li>

                  <li className="text-sm">
                    <Link
                      to="/licensing/"
                      className="font-body font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                    >
                      Disclosures and Licenses
                    </Link>
                  </li>

                  <div className="mb-3 mt-2.5 flex w-full justify-center space-x-5 md:mb-0 md:mt-0 md:w-auto md:justify-start">
                    <li className="text-sm">
                      <button
                        className="relative font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                        onClick={locationsClickHandler}
                      >
                        <span className="flex items-center justify-between">
                          States
                          <i
                            className={`far fa-chevron-down ml-1 transform text-sm transition-all duration-300 ease-linear ${
                              statesDropdownOpen ? "rotate-180" : "rotate-0"
                            }`}
                          ></i>
                        </span>
                        <ul
                          className={`absolute bottom-0 left-0 flex max-h-28 transform flex-col space-y-1.5 overflow-y-scroll bg-white px-3 pb-3 pt-4 text-left transition-all duration-300 ease-linear md:px-4 md:pb-4 md:pt-5 ${
                            statesDropdownOpen
                              ? "visible -translate-y-8 opacity-100"
                              : "invisible -translate-y-4 opacity-0"
                          }`}
                        >
                          {stateNodes.map((item, i) => (
                            <li
                              className="whitespace-nowrap pb-1.5 text-xs"
                              key={i}
                            >
                              <Link
                                to={`/${item.slug.current}/`}
                                className="font-body font-normal text-gray-700 no-underline hover:text-primary-500"
                              >
                                {item.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </button>
                    </li>

                    <li className="text-sm">
                      <button
                        className="relative font-normal text-typography-heading/70 no-underline hover:text-primary-500"
                        onClick={industriesClickHandler}
                      >
                        <span className="flex items-center justify-between">
                          Cities
                          <i
                            className={`far fa-chevron-down ml-1 transform text-sm transition-all duration-300 ease-linear ${
                              cityDropdownOpen ? "rotate-180" : "rotate-0"
                            }`}
                          ></i>
                        </span>
                        <ul
                          className={`absolute bottom-0 left-0 flex max-h-28 transform flex-col space-y-1.5 overflow-y-scroll bg-white px-3 pb-3 pt-4 text-left transition-all duration-300 ease-linear md:px-4 md:pb-4 md:pt-5 ${
                            cityDropdownOpen
                              ? "visible -translate-y-8 opacity-100"
                              : "invisible -translate-y-4 opacity-0"
                          }`}
                        >
                          {cityNodes.map((item, i) => (
                            <li
                              className="cursor-auto whitespace-nowrap pb-1.5 font-body text-xs text-gray-700"
                              key={i}
                            >
                              {item.slug ? (
                                <Link
                                  to={`/${item.slug.current}/`}
                                  className="font-normal text-gray-700 no-underline hover:text-primary-500"
                                >
                                  {item.title}
                                </Link>
                              ) : (
                                item.title
                              )}
                            </li>
                          ))}
                        </ul>
                      </button>
                    </li>
                    <li>
                      <a
                        className="group flex items-center justify-center space-x-1 whitespace-nowrap text-sm font-normal text-typography-heading/70 no-underline hover:text-primary-500 md:justify-start"
                        href="https://www.wisedigitalpartners.com/nest-by-wise/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>Powered by</span>
                        <img
                          src={nestLogo}
                          alt="NEST logo"
                          className="opacity-70 transition-opacity duration-300 ease-linear group-hover:opacity-100"
                        />
                      </a>
                    </li>
                  </div>
                </ul>

                <div className="mt-2 w-full text-sm md:mt-0 md:w-auto">
                  <StaticImage
                    src="../../images/Global/equal-housing-lender.png"
                    alt="Equal Housing Lender Logo"
                    loading="lazy"
                    width={55}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </footer>
      <ModalCareers />
      <ModalContact />
      <ModalApply />
    </>
  );
};

export default Footer;
