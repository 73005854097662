import React from "react";
import MicroModal from "micromodal";
import styled from "@emotion/styled";
import tw from "twin.macro";

import { Link } from "gatsby";
import ButtonSolid from "../Button/ButtonSolid";

import house from "../../images/Global/clarity_house-line.svg";
import refresh from "../../images/Global/akar-icons_arrow-cycle.svg";
const StyledModal = styled.div`
    .modalBottom  {
        &.is-open {
            ${tw`block`}
        }
        &[aria-hidden="false"] {
            .overlay {
                animation: mmfadeInBottom .5s cubic-bezier(0, 0, .2, 1);
            }
        }
        &[aria-hidden="false"] {
            .content-wrapper  {
                animation: mmslideInBottom .5s cubic-bezier(0, 0, .2, 1);
            }#promoBarClose  {
                animation: mmtslideInBottom .5s cubic-bezier(0, 0, .2, 1);
            }
        }
        &[aria-hidden="true"] {
            .overlay {
                animation: mmfadeOutBottom .5s cubic-bezier(0, 0, .2, 1);
            }
        }
        &[aria-hidden="true"] {
            .content-wrapper{
                animation: mmslideOutBottom .5s cubic-bezier(0, 0, .2, 1);
            }#promoBarClose {
                animation: mmtslideOutBottom .5s cubic-bezier(0, 0, .2, 1);
            }
        }
        .content-wrapper,#promoBarClose,
        .overlay {
            will-change: transform;
        } 
    }
    @keyframes mmfadeInBottom {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    @keyframes mmfadeOutBottom {
        from { opacity: 1; }
        to { opacity: 0; }
    }

    @keyframes mmslideInBottom {
        from { transform: translateY(100%); }
        to { transform: translateY(0); }
    }

    @keyframes mmslideOutBottom {
        from { transform: translateY(0); }
        to { transform: translateY(100%); }
    }

    @keyframes mmtslideInBottom {
        from { transform: translateY(65vh); }
        to { transform: translateY(0); }
    }

    @keyframes mmtslideOutBottom {
        from { transform: translateY(0vh); }
        to { transform: translateY(60vh); }
    }
}
`;

const BuydownModal = () => {
  if (typeof window !== `undefined`) {
    MicroModal.init({
      openTrigger: "data-modal-open",
      closeTrigger: "data-modal-close",
      disableFocus: true,
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }

  return (
    <StyledModal>
      <div
        className="modalBottom relative mt-16"
        data-modal-open="modal-buydown"
      >
        <div className="fixed -bottom-[5px]  z-40 w-full bg-primary-500 font-bold text-white">
          <div id="promo-bar" className=" py-6 text-center">
            <div className="container">
              <p className="m-0 h-[24px] p-0 text-[24px] leading-[24px]">
                Lock in my rates
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modalBottom relative z-50 hidden"
        id="modal-buydown"
        aria-hidden="false"
      >
        <div
          className="overlay fixed top-0 right-0 left-0 bottom-0 flex items-end bg-black bg-opacity-75 outline-none"
          tabIndex="-1"
          data-modal-close
        >
          <div className=" relative mt-16">
            <div
              id="promoBarClose"
              className="fixed bottom-[55vh]  z-40 w-full bg-primary-500 font-bold text-white"
              data-modal-close
            >
              <div className=" py-6 text-center">
                <div className="container">
                  <p className="m-0 h-[24px] p-0 text-[24px] leading-[24px]">
                    Lock in my rates
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="content-wrapper ml-auto h-screen max-h-[55vh] w-full  overflow-hidden bg-white py-16 px-6 md:py-20 md:px-20"
            role="dialog"
            aria-modal="true"
          >
            <header className="mb-8 text-center">
              <h4 className="">Select the type of Loan you’re looking for</h4>
            </header>
            <section className="w-full">
              <div className="container">
                <div className="grid grid-cols-12">
                  <div className=" col-span-6 flex justify-end px-10">
                    <Link className="no-underline" to="/make-a-payment/">
                      <div className="animation group block rounded-xl border-[6px] border-secondary-500 bg-white  p-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-6xl ">
                        <img
                          className="w-full px-4 pb-6"
                          alt="svg-house"
                          src={house}
                        ></img>
                        <ButtonSolid
                          text="Purhcase"
                          className=""
                          altStyle={2}
                        />
                      </div>
                    </Link>
                  </div>
                  <div className="col-span-6 flex px-10  ">
                    <Link className="no-underline" to="/home-refinance/">
                      <div className="animation group block rounded-xl border-[6px] border-primary-500 bg-white  p-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-6xl ">
                        <img
                          className="w-full px-4 pb-6"
                          alt="svg-repayment"
                          src={refresh}
                        ></img>
                        <ButtonSolid text="Refinance" className="group-item" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <i
              className="close fal fa-times hover:text-primary focus:text-primary active:text-primary absolute top-0 right-0 mr-8 mt-12 cursor-pointer text-2xl text-black transition-all duration-300 ease-linear md:mr-12"
              data-modal-close
            ></i>
          </div>
        </div>
      </div>
    </StyledModal>
  );
};
export default BuydownModal;
