import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useOnClickOutside } from "../../hooks";
import Link from "gatsby-plugin-transition-link";

import Burger from "./Burger";
import OffCanvas from "./OffCanvas";
import ButtonSolid from "../Button/ButtonSolid";
// import ButtonGhost from "../Button/ButtonGhost";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import payment from "../../images/Global/payment.svg";
import applyNow from "../../images/Global/Apply Now.svg";
import signIn from "../../images/Global/Login.svg";

const MainNav = ({ navigationStyle, scrolled, offcanvasOffset, pageType }) => {
  // determine if offcanvas is open
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  // determine button styles
  // const [buttonAltStyle, setButtonAltStyle] = useState(0);

  const [subMenuHovering1, setSubMenuHovering1] = useState(false);
  const isHoveringSubMenu1 = () => setSubMenuHovering1(true);
  const notHoveringSubMenu1 = () => setSubMenuHovering1(false);

  const [subMenuHovering2, setSubMenuHovering2] = useState(false);
  const isHoveringSubMenu2 = () => setSubMenuHovering2(true);
  const notHoveringSubMenu2 = () => setSubMenuHovering2(false);

  const [subMenuHovering3, setSubMenuHovering3] = useState(false);
  const isHoveringSubMenu3 = () => setSubMenuHovering3(true);
  const notHoveringSubMenu3 = () => setSubMenuHovering3(false);

  const [subMenuHovering4, setSubMenuHovering4] = useState(false);
  const isHoveringSubMenu4 = () => setSubMenuHovering4(true);
  const notHoveringSubMenu4 = () => setSubMenuHovering4(false);

  // Drilldown menus
  const hideDrilldown = "translate-x-full";
  const showDrilldown = "translate-x-0";

  const [setPurchasesDrilldown, setPurchasesDrilldownState] =
    useState(hideDrilldown);
  const [setResourcesDrilldown, setResourcesDrilldownState] =
    useState(hideDrilldown);
  const [setAboutDrilldown, setAboutDrilldownState] = useState(hideDrilldown);
  const [setLocationsDrilldown, setLocationsDrilldownState] =
    useState(hideDrilldown);

  // handle click of navigation items
  const clickHandler = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  // close offcanvas onclick outside
  const node = useRef();
  useOnClickOutside(node, () => setOffcanvasOpen(false));

  // useEffect(() => {
  //   navigationStyle !== "overlap" && scrolled === false
  //     ? setButtonAltStyle(0)
  //     : navigationStyle === "overlap" && scrolled === false
  //     ? setButtonAltStyle(2)
  //     : setButtonAltStyle(0);
  // }, [buttonAltStyle, scrolled, navigationStyle]);

  const data = useStaticQuery(graphql`
    {
      darkLogo: file(relativePath: { eq: "Global/Logo-Dark.png" }) {
        publicURL
      }
    }
  `);

  // Define logos based on header style
  let logo = null;

  if (navigationStyle === "overlap") {
    logo = data.darkLogo.publicURL;
  } else {
    logo = data.darkLogo.publicURL;
  }

  if (scrolled) {
    logo = data.darkLogo.publicURL;
  }

  if (offcanvasOpen) {
    logo = data.darkLogo.publicURL;
  }

  const navigation = {
    loanPrograms: [
      {
        name: "All Loan Programs",
        href: "/home-loan-programs/",
      },
      {
        name: "FHA",
        href: "/fha-home-loans/",
      },
      {
        name: "VA",
        href: "/va-home-loans/",
      },
      {
        name: "USDA",
        href: "/usda-home-loans/",
      },
      {
        name: "Jumbo",
        href: "/jumbo-home-loans/",
      },
      {
        name: "Conventional",
        href: "/conventional-home-loans/",
      },
    ],
    rateOptions: [
      {
        name: "All Mortgage Rate Options",
        href: "/mortgage-rate-options/",
      },
      {
        name: "Fixed Rate",
        href: "/fixed-rate-mortgage/",
      },
      {
        name: "Adjustable ARM",
        href: "/adjustable-rate-mortgage/",
      },
      {
        name: "Interest Only",
        href: "/interest-only-mortgage/",
      },
      {
        name: "Graduated Payments",
        href: "/graduated-payment-mortgage/",
      },
      {
        name: "Temporary Interest Rate Buydown",
        href: "/temporary-interest-rate-buydown/",
      },
    ],
    resources: [
      {
        name: "Loan Process",
        href: "/home-loan-process/",
      },
      {
        name: "Mortgage Basics",
        href: "/mortgage-basics/",
      },
      {
        name: "Mortgage Calculators",
        href: "/mortgage-calculators/",
      },
      {
        name: "Blog",
        href: "/blog/",
      },
      {
        name: "FAQs",
        href: "/faqs/",
      },
    ],
    about: [
      {
        name: "Company",
        href: "/about/",
      },
      {
        name: "Meet the Team",
        href: "/meet-the-team/",
      },
      {
        name: "Reviews",
        href: "/reviews/",
      },
    ],
    // locations: [
    //   {
    //     name: "Arizona",
    //     href: "/arizona-mortgage-lender/",
    //   },
    //   {
    //     name: "California",
    //     href: "/california-mortgage-lender/",
    //   },
    //   {
    //     name: "Nevada",
    //     href: "/nevada-mortgage-lender/",
    //   },
    //   {
    //     name: "Colorado",
    //     href: "/colorado-mortgage-lender/",
    //   },
    //   {
    //     name: "Oregon",
    //     href: "/oregon-mortgage-lender/",
    //   },
    //   {
    //     name: "Texas",
    //     href: "/texas-mortgage-lender/",
    //   },
    //   {
    //     name: "Washington",
    //     href: "/washington-mortgage-lender/",
    //   },
    //   {
    //     name: "Wyoming",
    //     href: "/wyoming-mortgage-lender/",
    //   },
    // ],
  };

  return (
    <nav
      id="main-navigation"
      className={`flex h-16 w-full items-center transition duration-300 ease-linear md:h-20 ${
        navigationStyle === "overlap"
          ? "absolute lg:bg-transparent"
          : "lg:bg-white"
      } ${offcanvasOpen && "!bg-white"} ${
        scrolled && "!fixed top-0 left-0 z-50 w-full !bg-white !shadow-4xl"
      } `}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container flex items-center justify-between">
        <div className="flex flex-auto items-center justify-start">
          <Link to="/">
            <img
              src={logo}
              alt="Right Start Mortgage Logo"
              width={189}
              className="w-[136px] lg:w-[189px]"
            />
          </Link>
        </div>

        <div className="flex flex-auto items-center justify-end">
          {pageType === "Landing" ? (
            <div className="flex items-center space-x-6">
              <div className="hidden font-heading text-sm font-semibold text-gray-900 md:block">
                Call Us Today!
              </div>
              <ButtonSolid
                href="tel:866-299-1600"
                text="(866) 299-1600"
                altStyle={2}
                className="!text-lg"
              />
            </div>
          ) : (
            <>
              <ul
                id="navigation-desktop"
                className="hidden md:mr-6 lg:mr-8 lg:flex lg:flex-row lg:items-center lg:justify-end lg:space-x-5 xl:space-x-8"
              >
                <li
                  className="relative"
                  role="presentation"
                  onMouseEnter={isHoveringSubMenu1}
                  onMouseLeave={notHoveringSubMenu1}
                >
                  <Link
                    to="/home-purchase/"
                    className={`relative inline cursor-default pb-8 font-heading text-sm font-semibold no-underline ${
                      navigationStyle === "overlap"
                        ? "text-white hover:text-white"
                        : "text-typography-heading hover:text-secondary-500"
                    } ${subMenuHovering1 && "!text-secondary-500"} ${
                      scrolled &&
                      "text-typography-heading hover:text-secondary-500"
                    }`}
                  >
                    Purchase
                  </Link>
                  <div
                    className={`absolute top-0 z-10 w-[683px] -translate-x-5 transform border-t-2 border-secondary-500 bg-white px-10 py-8 shadow-6xl transition-all duration-300 ease-linear ${
                      subMenuHovering1
                        ? "visible translate-y-[52px] opacity-100"
                        : "invisible translate-y-20 opacity-0"
                    }`}
                  >
                    <div className="flex justify-between space-x-4">
                      <div>
                        <div className="mb-2.5">
                          <Link
                            to="/home-loan-programs/"
                            className="text-typography-heading no-underline"
                          >
                            Loan Programs
                          </Link>
                        </div>
                        <ul className={`flex flex-col space-y-2.5`}>
                          {navigation.loanPrograms.slice(1).map((item, i) => {
                            return (
                              <li key={i} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  className="font-heading text-sm font-medium text-typography-heading no-underline hover:text-secondary-500"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <div>
                        <div className="mb-2.5">
                          <Link
                            to="/mortgage-rate-options/"
                            className="text-typography-heading no-underline"
                          >
                            Mortgage Rate Options
                          </Link>
                        </div>
                        <ul className={`flex flex-col space-y-2.5`}>
                          {navigation.rateOptions.slice(1).map((item, i) => {
                            return (
                              <li key={i} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  className="font-heading text-sm font-medium text-typography-heading no-underline hover:text-secondary-500"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <div>
                        <div className="mb-2.5">
                          <Link
                            to="/home-loan-programs/"
                            className="text-typography-heading no-underline"
                          >
                            Get Started
                          </Link>
                        </div>
                        <ul className={`flex flex-col space-y-2.5`}>
                          <li className="whitespace-nowrap">
                            <Link
                              to="/request-rates/"
                              className="font-heading text-sm font-medium text-typography-heading no-underline hover:text-secondary-500"
                            >
                              Request Rates
                            </Link>
                          </li>
                          <li className="whitespace-nowrap">
                            <button
                              data-modal-open="modal-apply"
                              className="font-heading text-sm font-medium text-typography-heading no-underline hover:text-secondary-500"
                            >
                              Apply Now
                            </button>
                          </li>
                          <li className="whitespace-nowrap">
                            <a
                              href="https://www.yourmortgageonline.com/"
                              target="_blank"
                              rel="noreferrer"
                              className="font-heading text-sm font-medium text-typography-heading no-underline hover:text-secondary-500"
                            >
                              Login
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <Link
                    to="/home-refinance/"
                    className={`inline font-heading text-sm font-semibold no-underline ${
                      navigationStyle === "overlap"
                        ? "text-white hover:text-white"
                        : "text-typography-heading hover:text-secondary-500"
                    } ${
                      scrolled &&
                      "text-typography-heading hover:text-secondary-500"
                    }`}
                  >
                    Refinance
                  </Link>
                </li>

                <li
                  className="relative"
                  role="presentation"
                  onMouseEnter={isHoveringSubMenu2}
                  onMouseLeave={notHoveringSubMenu2}
                >
                  <Link
                    to="#"
                    className={`relative inline cursor-default pb-8 font-heading text-sm font-semibold no-underline ${
                      navigationStyle === "overlap"
                        ? "text-white hover:text-white"
                        : "text-typography-heading hover:text-secondary-500"
                    } ${subMenuHovering2 && "!text-secondary-500"}  ${
                      scrolled &&
                      "text-typography-heading hover:text-secondary-500"
                    }`}
                  >
                    Resources
                  </Link>
                  <ul
                    className={`absolute top-0 z-10 -translate-x-5 transform flex-col space-y-2.5 border-t-2 border-secondary-500 bg-white px-6 py-5 shadow-6xl transition-all duration-300 ease-linear ${
                      subMenuHovering2
                        ? "visible translate-y-[52px] opacity-100"
                        : "invisible translate-y-20 opacity-0"
                    }`}
                  >
                    {navigation.resources.map((item, i) => {
                      return (
                        <li key={i} className="whitespace-nowrap">
                          <Link
                            to={item.href}
                            className="font-heading text-sm font-semibold text-typography-heading no-underline hover:text-secondary-500"
                          >
                            {item.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>

                <li
                  className="relative"
                  role="presentation"
                  onMouseEnter={isHoveringSubMenu3}
                  onMouseLeave={notHoveringSubMenu3}
                >
                  <Link
                    to="/about/"
                    className={`relative inline cursor-auto pb-8 font-heading text-sm font-semibold no-underline ${
                      navigationStyle === "overlap"
                        ? "text-white hover:text-white"
                        : "text-typography-heading hover:text-secondary-500"
                    } ${subMenuHovering3 && "!text-secondary-500"}  ${
                      scrolled &&
                      "text-typography-heading hover:text-secondary-500"
                    }`}
                  >
                    About
                  </Link>
                  <ul
                    className={`absolute top-0 z-10 -translate-x-5 transform flex-col space-y-2.5 border-t-2 border-secondary-500 bg-white px-6 py-5 shadow-6xl transition-all duration-300 ease-linear ${
                      subMenuHovering3
                        ? "visible translate-y-[52px] opacity-100"
                        : "invisible translate-y-20 opacity-0"
                    }`}
                  >
                    {navigation.about.map((item, i) => (
                      <li key={i} className="whitespace-nowrap">
                        <Link
                          to={item.href}
                          className="font-heading text-sm font-semibold text-typography-heading no-underline hover:text-secondary-500"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

{/*   // <li
                //   className="relative"
                //   role="presentation"
                //   onMouseEnter={isHoveringSubMenu4}
                //   onMouseLeave={notHoveringSubMenu4}
                // >
                //   <Link
                //     to="/locations/"
                //     className={`relative inline cursor-auto pb-8 font-heading text-sm font-semibold no-underline ${
                //       navigationStyle === "overlap"
                //         ? "text-white hover:text-white"
                //         : "text-typography-heading hover:text-secondary-500"
                //     } ${subMenuHovering4 && "!text-secondary-500"}  ${
                //       scrolled &&
                //       "text-typography-heading hover:text-secondary-500"
                //     }`}
                //   >
                //     Locations
                //   </Link>
                //   <ul
                //     className={`absolute top-0 z-10 -translate-x-5 transform flex-col space-y-2.5 border-t-2 border-secondary-500 bg-white px-6 py-5 shadow-6xl transition-all duration-300 ease-linear ${
                //       subMenuHovering4
                //         ? "visible translate-y-[52px] opacity-100"
                //         : "invisible translate-y-20 opacity-0"
                //     }`}
                //   >
                //     {navigation.locations.map((item, i) => (
                //       <li key={i} className="whitespace-nowrap">
                //         <Link
                //           to={item.href}
                //           className="font-heading text-sm font-semibold text-typography-heading no-underline hover:text-secondary-500"
                //         >
                //           {item.name}
                //         </Link>
                //       </li>
                //     ))}
                //   </ul>
                // </li> */}

                <li>
                  <button
                    data-modal-open="modal-contact"
                    className={`inline font-heading text-sm font-semibold no-underline ${
                      navigationStyle === "overlap"
                        ? "text-white hover:text-white"
                        : "text-typography-heading hover:text-secondary-500"
                    } ${
                      scrolled &&
                      "text-typography-heading hover:text-secondary-500"
                    }`}
                  >
                    Contact
                  </button>
                </li>

                <li>
                  <Link
                    to="/careers/"
                    className={`inline font-heading text-sm font-semibold no-underline ${
                      navigationStyle === "overlap"
                        ? "text-white hover:text-white"
                        : "text-typography-heading hover:text-secondary-500"
                    } ${
                      scrolled &&
                      "text-typography-heading hover:text-secondary-500"
                    }`}
                  >
                    Careers
                  </Link>
                </li>
              </ul>

              <div className="hidden items-center space-x-4 lg:inline-flex">
                <ButtonSolid
                  href="/request-rates/"
                  text="Request Rates"
                  altStyle={2}
                />
              </div>

              <div className="lg:hidden" ref={node}>
                {/* Burger */}
                <Burger
                  offcanvasOpen={offcanvasOpen}
                  setOffcanvasOpen={setOffcanvasOpen}
                  navigationStyle={navigationStyle}
                  scrolled={scrolled}
                  aria-controls="offcanvas-navigation"
                />

                {/* Mobile Nav */}
                <OffCanvas
                  offcanvasOpen={offcanvasOpen}
                  offcanvasOffset={offcanvasOffset}
                  id="offcanvas-navigation"
                >
                  <div className="pb-48">
                    {/* Hub pages Drilldown */}
                    <div className="h-full transition-all duration-500 ease-in-out">
                      <ul className="mb-9 flex flex-col space-y-6 border-b border-white/30 pb-9">
                        <li>
                          <button
                            onClick={() =>
                              setPurchasesDrilldownState(showDrilldown)
                            }
                            className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                          >
                            Purchase
                          </button>
                        </li>
                        <li>
                          <Link
                            to="/home-refinance/"
                            onClick={clickHandler}
                            className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                          >
                            Refinance
                          </Link>
                        </li>

                        <li>
                          <button
                            onClick={() =>
                              setResourcesDrilldownState(showDrilldown)
                            }
                            className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                          >
                            Resources
                          </button>
                        </li>

                        <li>
                          <button
                            onClick={() =>
                              setAboutDrilldownState(showDrilldown)
                            }
                            className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                          >
                            About
                          </button>
                        </li>

                       {/* // <li>
                        //   <button
                        //     onClick={() =>
                        //       setLocationsDrilldownState(showDrilldown)
                        //     }
                        //     className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                        //   >
                        //     Locations
                        //   </button>
                        // </li> */}

                        <li>
                          <button
                            data-modal-open="modal-mortage"
                            className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                          >
                            Contact
                          </button>
                        </li>
                      </ul>

                      <div className="mb-24 space-y-6">
                        <div>
                          <Link
                            to="/make-a-payment/"
                            className="flex items-center space-x-2 font-heading text-sm font-medium text-white no-underline hover:text-white"
                          >
                            <img src={payment} alt="Make a Payment" />
                            <span>Make a Payment</span>
                          </Link>
                        </div>
                        <div>
                          <a
                            href="https://www.yourmortgageonline.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center space-x-2 font-heading text-sm font-medium text-white no-underline hover:text-white"
                          >
                            <img src={signIn} alt="Login" />
                            <span>Login</span>
                          </a>
                        </div>
                        <div>
                          <button
                            data-modal-open="modal-apply"
                            className="flex items-center space-x-2 font-heading text-sm font-medium text-white no-underline hover:text-white"
                          >
                            <img src={applyNow} alt="Apply Now" />
                            <span>Apply Now</span>
                          </button>
                        </div>
                      </div>

                      <div className="max-w-[420px] space-y-4">
                        <ButtonSolid
                          href="tel:800-520-5626"
                          text="(800) 520-5626"
                          className="w-full"
                        />
                        <ButtonSolid
                          href="/request-rates/"
                          text="Request Rates"
                          altStyle={2}
                          className="w-full"
                        />
                      </div>
                    </div>

                    {/* About Drilldown */}
                    <div
                      className={`absolute top-0 left-0 z-10 h-full w-full transform bg-primary-900 px-10 pt-10 pb-12 transition-all duration-500 ease-in-out ${setAboutDrilldown}`}
                    >
                      <button
                        onClick={() => setAboutDrilldownState(hideDrilldown)}
                        className="flex items-center space-x-2 text-white no-underline hover:text-secondary-500"
                      >
                        <i className="far fa-arrow-left text-lg"></i>
                        <span className="text-sm font-semibold">Go Back</span>
                      </button>

                      <ul className="mt-9 flex flex-col space-y-6">
                        {navigation.about.map((item) => (
                          <li key={item.name} className="whitespace-nowrap">
                            <Link
                              fade
                              to={item.href}
                              onClick={clickHandler}
                              className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Purchase Drilldown */}
                    <div
                      className={`absolute top-0 left-0 z-10 h-full w-full transform bg-primary-900 px-10 pt-10 pb-12 transition-all duration-500 ease-in-out ${setPurchasesDrilldown}`}
                    >
                      <button
                        onClick={() =>
                          setPurchasesDrilldownState(hideDrilldown)
                        }
                        onKeyDown={() =>
                          setPurchasesDrilldownState(hideDrilldown)
                        }
                        className="flex items-center space-x-2 text-white no-underline hover:text-secondary-500"
                      >
                        <i className="far fa-arrow-left text-lg"></i>
                        <span className="text-sm font-semibold">Go Back</span>
                      </button>
                      <ul className="mt-9 flex flex-col space-y-6">
                        <li className="whitespace-nowrap">
                          <Link
                            fade
                            to="/home-purchase/"
                            onClick={clickHandler}
                            className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                          >
                            Purchase
                          </Link>
                        </li>

                        <Accordion
                          allowZeroExpanded={true}
                          className="flex flex-col space-y-8"
                        >
                          <li>
                            <AccordionItem uuid={1}>
                              <AccordionItemButton className="focus:outline-none">
                                <AccordionItemState>
                                  {(state) => {
                                    return (
                                      <>
                                        <div
                                          className={`font-heading text-lg font-bold no-underline transition-colors duration-300 ease-linear ${
                                            state.expanded
                                              ? "text-white"
                                              : "text-white hover:text-secondary-500"
                                          }`}
                                        >
                                          Loan Programs
                                        </div>
                                      </>
                                    );
                                  }}
                                </AccordionItemState>
                              </AccordionItemButton>

                              <AccordionItemPanel className="pt-6">
                                <ul className="space-y-3.5 pl-4">
                                  {navigation.loanPrograms.map((item, i) => {
                                    return (
                                      <li key={i} className="whitespace-nowrap">
                                        <Link
                                          to={item.href}
                                          onClick={clickHandler}
                                          className="block bg-transparent font-heading font-semibold text-white no-underline hover:text-secondary-500"
                                        >
                                          {item.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </AccordionItemPanel>
                            </AccordionItem>
                          </li>

                          <li>
                            <AccordionItem uuid={2}>
                              <AccordionItemButton className="focus:outline-none">
                                <AccordionItemState>
                                  {(state) => {
                                    return (
                                      <>
                                        <div
                                          className={`font-heading text-lg font-bold no-underline transition-colors duration-300 ease-linear ${
                                            state.expanded
                                              ? "text-white"
                                              : "text-white hover:text-secondary-500"
                                          }`}
                                        >
                                          Mortgage Rate Options
                                        </div>
                                      </>
                                    );
                                  }}
                                </AccordionItemState>
                              </AccordionItemButton>

                              <AccordionItemPanel className="pt-6">
                                <ul className="space-y-3.5 pl-4">
                                  {navigation.rateOptions.map((item, i) => {
                                    return (
                                      <li key={i} className="whitespace-nowrap">
                                        <Link
                                          to={item.href}
                                          onClick={clickHandler}
                                          className="block bg-transparent font-heading font-semibold text-white no-underline hover:text-secondary-500"
                                        >
                                          {item.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </AccordionItemPanel>
                            </AccordionItem>
                          </li>
                        </Accordion>
                      </ul>
                    </div>

                    {/* Locations Drilldown */}
                   {/* // <div
                    //   className={`absolute top-0 left-0 z-10 h-full w-full transform bg-primary-900 px-10 pt-10 pb-12 transition-all duration-500 ease-in-out ${setLocationsDrilldown}`}
                    // >
                    //   <button
                    //     onClick={() =>
                    //       setLocationsDrilldownState(hideDrilldown)
                    //     }
                    //     onKeyDown={() =>
                    //       setLocationsDrilldownState(hideDrilldown)
                    //     }
                    //     className="flex items-center space-x-2 text-white no-underline hover:text-secondary-500"
                    //   >
                    //     <i className="far fa-arrow-left text-lg"></i>
                    //     <span className="text-sm font-semibold">Go Back</span>
                    //   </button>

                    //   <ul className="mt-9 flex flex-col space-y-6">
                    //     {navigation.locations.map((item) => (
                    //       <li key={item.name} className="whitespace-nowrap">
                    //         <Link
                    //           fade
                    //           to={item.href}
                    //           onClick={clickHandler}
                    //           className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                    //         >
                    //           {item.name}
                    //         </Link>
                    //       </li>
                    //     ))}
                    //   </ul>
                    // </div> */}

                    {/* Resources Drilldown */}
                    <div
                      className={`absolute top-0 left-0 z-10 h-full w-full transform bg-primary-900 px-10 pt-10 pb-12 transition-all duration-500 ease-in-out ${setResourcesDrilldown}`}
                    >
                      <button
                        onClick={() =>
                          setResourcesDrilldownState(hideDrilldown)
                        }
                        className="flex items-center space-x-2 text-white no-underline hover:text-secondary-500"
                      >
                        <i className="far fa-arrow-left text-lg"></i>
                        <span className="text-sm font-semibold">Go Back</span>
                      </button>
                      <ul className="mt-9 flex flex-col space-y-6">
                        {navigation.resources.map((item) => (
                          <li key={item.name} className="whitespace-nowrap">
                            <Link
                              fade
                              to={item.href}
                              onClick={clickHandler}
                              className="font-heading text-lg font-bold text-white no-underline hover:text-secondary-500"
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <ul className={`flex flex-col space-y-2.5`}>
                      <li className="whitespace-nowrap">
                        <Link
                          to="/request-rates/"
                          className="font-heading text-sm font-medium text-typography-heading no-underline hover:text-secondary-500"
                        >
                          Request Rates
                        </Link>
                      </li>
                      <li className="whitespace-nowrap">
                        <button
                          data-modal-open="modal-apply"
                          className="font-heading text-sm font-medium text-typography-heading no-underline hover:text-secondary-500"
                        >
                          Apply Now
                        </button>
                      </li>
                      <li className="whitespace-nowrap">
                        <a
                          href="https://www.yourmortgageonline.com"
                          target="_blank"
                          rel="noreferrer"
                          className="font-heading text-sm font-medium text-typography-heading no-underline hover:text-secondary-500"
                        >
                          Login
                        </a>
                      </li>
                    </ul>
                  </div>
                </OffCanvas>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};
export default MainNav;
