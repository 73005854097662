import React from "react";
import MicroModal from "micromodal";
import styled from "@emotion/styled";

import phone from "../../images/Global/phone-dark.svg";
import Contact from "../Form/Contact";

const StyledModal = styled.div`
  .modal {
    &[aria-hidden="false"] {
      .overlay {
        animation: mmfadeIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="false"] {
      .content-wrapper {
        animation: mmslideIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .overlay {
        animation: mmfadeOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .content-wrapper {
        animation: mmslideOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
`;

const ContactModal = () => {
  if (typeof window !== "undefined") {
    MicroModal.init({
      openTrigger: "data-modal-open",
      closeTrigger: "data-modal-close",
      disableFocus: true,
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }

  return (
    <StyledModal>
      <div
        className="modal relative z-50 hidden"
        id="modal-contact"
        aria-hidden="false"
      >
        <div
          className="overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-black/40 outline-none"
          tabIndex="-1"
          data-modal-close
        >
          <div
            className="content-wrapper ml-auto h-screen w-full max-w-[720px] overflow-auto bg-white"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between border-b border-gray-500/20 px-5 pt-14 pb-4 md:py-4">
              <a
                href="tel:800-520-5626"
                className="hover:text-primary-800 flex items-center space-x-2 font-bold text-primary-500 no-underline hover:text-primary-500"
              >
                <img src={phone} alt="Phone" />
                <span>(800) 520-5626</span>
              </a>
              <i
                className="close far fa-times cursor-pointer text-xl text-black transition-all duration-300 ease-linear hover:text-primary-500"
                data-modal-close
              ></i>
            </div>

            <div className="px-4 pt-8 pb-24 md:px-18">
              <header className="mb-8 md:mb-9">
                <p className="heading-three">Let’s Talk Loans</p>
                <p>
                  Have questions? Need more information before you make a
                  decision? We’re here to help!
                </p>
              </header>

              <Contact />
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
};
export default ContactModal;
