import React, { useState, useEffect, Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import MicroModal from "micromodal";
import styled from "@emotion/styled";
import { Listbox, Transition } from "@headlessui/react";

import { mapEdgesToNodes } from "../../lib/helpers";
import ButtonSolid from "../Button/ButtonSolid";

const StyledModal = styled.div`
  .modal {
    &[aria-hidden="false"] {
      .overlay {
        animation: mmfadeIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="false"] {
      .content-wrapper {
        animation: mmpopUp 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .overlay {
        animation: mmfadeOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .content-wrapper {
        animation: mmpopOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
`;

const Modal = () => {
  if (typeof window !== "undefined") {
    MicroModal.init({
      openTrigger: "data-modal-open",
      closeTrigger: "data-modal-close",
      disableFocus: true,
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }

  const data = useStaticQuery(graphql`
    {
      teamMembers: allSanityTeamMember(
        sort: { fields: [name], order: ASC }
        filter: { applyLink: { ne: null } }
      ) {
        edges {
          node {
            name
            applyLink
          }
        }
      }
    }
  `);

  let loanOfficerNodes = (data || {}).teamMembers
    ? mapEdgesToNodes(data.teamMembers)
    : [];

  loanOfficerNodes.unshift({
    name: "Select a Loan Officer",
  });

  const [step1, setStep1] = useState("block");
  const [step2, setStep2] = useState("hidden");
  const [selectedLoanOfficer, setSelectedLoanOfficer] = useState(
    loanOfficerNodes[0]
  );
  const [selectedApplyLink, setSelectedApplyLink] = useState("");

  const step1Next = () => {
    setStep1("hidden");
    setStep2("block");
  };

  const step2Back = () => {
    setStep1("block");
    setStep2("hidden");
  };

  useEffect(() => {
    setSelectedApplyLink(selectedLoanOfficer.applyLink);
  }, [selectedApplyLink, selectedLoanOfficer]);

  return (
    <StyledModal>
      <div
        className="modal relative z-50 hidden"
        id="modal-apply"
        aria-hidden="false"
      >
        <div
          className="overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-black/40 outline-none"
          tabIndex="-1"
        >
          <div className="container">
            <div
              className="content-wrapper relative mx-auto w-full max-w-[600px] overflow-auto rounded-3xl bg-white py-20 px-8 text-center md:px-16"
              role="dialog"
              aria-modal="true"
            >
              <div className="absolute right-6 top-6 z-10">
                <i
                  className="close far fa-times cursor-pointer text-3xl text-black transition-all duration-300 ease-linear hover:text-primary-500 md:text-3xl"
                  data-modal-close
                ></i>
              </div>
              <div className={`text-center ${step1}`}>
                <div className="heading-five mb-8 md:mb-9">
                  Are you already working with a loan officer?
                </div>
                <div className="justify-center space-y-4 md:flex md:space-x-4 md:space-y-0">
                  <ButtonSolid
                    onClick={step1Next}
                    text="Yes"
                    altStyle={2}
                    className="w-full md:w-auto"
                  />
                  <ButtonSolid
                    href="https://right-start-mortgage-inc.himaxwell.com/home/right-start-mortgage"
                    outboundLink={true}
                    text="No"
                    className="w-full md:w-auto"
                  />
                </div>
              </div>

              <div className={`${step2}`}>
                <button
                  className="absolute top-6 left-6 flex items-center space-x-2 text-typography-heading no-underline hover:text-primary-500"
                  type="button"
                  onClick={step2Back}
                >
                  <i className="far fa-arrow-left text-lg"></i>
                  <span className="text-sm font-semibold">Go Back</span>
                </button>

                <div className="heading-five mb-8 md:mb-9">
                  Please select your loan officer
                </div>

                <div className="relative mx-auto max-w-[400px]">
                  <Listbox
                    value={selectedLoanOfficer}
                    onChange={setSelectedLoanOfficer}
                  >
                    <div className="relative mt-1">
                      <Listbox.Button className="relative flex h-12 w-full cursor-default items-center justify-between rounded-md border border-gray-300 bg-white px-4 text-left font-normal no-underline focus:outline-none">
                        <span className="block truncate text-typography-heading">
                          {selectedLoanOfficer.name}
                        </span>
                        <i className="far fa-chevron-down text-xl text-typography-heading"></i>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="relative left-0 z-10 mt-2 max-h-36 w-full overflow-auto rounded-md bg-white text-left text-sm shadow-5xl focus:outline-none">
                          {loanOfficerNodes
                            .slice(1)
                            .map((specialty, specialtyIdx) => (
                              <Listbox.Option
                                key={specialtyIdx}
                                disabled={specialty.unavailable}
                                value={specialty}
                                className={({ active }) =>
                                  `${
                                    active
                                      ? "bg-primary-50 text-gray-900"
                                      : "text-gray-900"
                                  } 
                                ${
                                  specialty.unavailable
                                    ? "bg-primary-900 font-semibold !text-white"
                                    : ""
                                }
                          relative cursor-default select-none py-2 px-4`
                                }
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`${
                                        selected && "text-primary-500"
                                      } block truncate`}
                                    >
                                      {specialty.name}
                                    </span>
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                  <div className="mt-8">
                    <ButtonSolid
                      href={selectedApplyLink}
                      outboundLink={true}
                      text="Start Application"
                      altStyle={2}
                      className={`${
                        selectedLoanOfficer.name === "Select a Loan Officer" &&
                        "hidden"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
};
export default Modal;
